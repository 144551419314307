<template lang="pug">
v-card(v-if="Object.keys(sailorDocument).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div {{ $t('civilPassport.view').toUpperCase() }}
      span(v-if="checkAccess('backOffice')").ml-1 (ID: {{ sailorDocument.id }})
    ControlButtons(:controlButtons="controlButtons")
  v-card-text
    v-divider
    Info(v-if="sailorDocument.behavior?.viewInfoBlock && !$route.query.viewEditBlock" :sailorDocument="sailorDocument" :comments="comments")
    Edit(v-else-if="sailorDocument.behavior?.viewEditBlock || $route.query.viewEditBlock" :passport="sailorDocument" actionType="edit")
    EditStatus(v-else-if="sailorDocument.behavior?.viewEditStatusBlock" :sailorDocument="sailorDocument")
    ViewPhotoList(
      v-else-if="sailorDocument.behavior?.viewFilesBlock"
      :files="sailorDocument.photo"
      :sailorDocument="sailorDocument"
      :documentType="type"
      :deleteFile="removeFileCitizenPassport"
      :editFile="updateFileCitizenPassport"
      isShowEdit
      )
</template>

<script>
import { back, copyToClipboard, deleteConfirmation, viewDetailedComponent } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  name: 'SailorCitizenPassportDocument',
  components: {
    Info: () => import('./Info.vue'),
    Edit: () => import('./Form.vue'),
    EditStatus: () => import('./EditStatus.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewFile/ViewFile.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons.vue')
  },
  data () {
    return {
      type: 'civilPassport',
      comments: [],
      viewDetailedComponent,
      deleteConfirmation,
      checkAccess,
      back,
      controlButtons: [
        {
          id: 8,
          name: 'mdi-content-copy',
          action: () => copyToClipboard(location.href),
          color: '#42627e',
          tooltip: 'documentIconControl.copyLink',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewInfoBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditBlock'),
          checkAccess: () => checkAccess('civilPassport', 'edit', this.sailorDocument),
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-sync',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditStatusBlock'),
          checkAccess: () => checkAccess('civilPassport', 'editStatus', this.sailorDocument),
          color: '#42627e',
          tooltip: 'documentIconControl.editStatus',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 5,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewFilesBlock'),
          checkAccess: () => checkAccess('civilPassport', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.files',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 6,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteDocument(),
          checkAccess: () => checkAccess('civilPassport', 'delete', this.sailorDocument),
          color: '#F73E07',
          tooltip: 'documentIconControl.delete',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 7,
          name: 'mdi-close',
          action: () => this.$router.push({ name: 'passports-citizen', params: { id: this.$route.params.id } }),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['sailorDocumentByID']),
    ...mapState({
      sailorDocument: state => state.sailor.sailorCitizenPassportById
    })
  },
  watch: {
    sailorDocument: {
      handler () {},
      deep: true
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['removeSailorCitizenPassports', 'getSailorCitizenPassportsComments', 'getSailorCitizenPassportById',
      'removeFileCitizenPassport', 'updateFileCitizenPassport']),
    ...mapMutations(['decrementBadgeCount']),
    async getData () {
      this.getSailorCitizenPassportById({ ...this.$route.params })
    },

    deleteDocument () {
      deleteConfirmation().then(async confirmation => {
        if (confirmation) {
          const response = await this.removeSailorCitizenPassports({ ...this.$route.params })
          if (SUCCESS_CODE.includes(response.code)) {
            this.$notification.success('deletedDocument')
            back('passports-citizen')
          } else this.$notification.error('cantDeleteDoc')
        }
      })
    }
  }
}
</script>
